import React from 'react';
import { TranslateFunction } from 'react-utilities';
import { TGetUniverseVoiceStatus } from '../../common/types/bedev1Types';
import { FeatureGameDetails } from '../../common/constants/translationConstants';

type TGameDescriptionTableChatFeaturesDisplayProps = {
  universeVoiceStatus: TGetUniverseVoiceStatus;
  translate: TranslateFunction;
};

const GameDescriptionTableChatFeaturesDisplay = ({
  universeVoiceStatus,
  translate
}: TGameDescriptionTableChatFeaturesDisplayProps): JSX.Element => {
  const { isUniverseEnabledForVoice, isUniverseEnabledForAvatarVideo } = universeVoiceStatus;

  return (
    <ul className='text-lead text-overflow chat-feature-list'>
      {isUniverseEnabledForVoice && (
        <li className='chat-feature-list-item'>
          <p id='microphone-enabled-label' className='text-lead font-caption-body'>
            <span className='chatfeatures icon-voice-mic-unmuted-16x16-hover' />
            {translate(FeatureGameDetails.LabelMicrophone)}
          </p>
        </li>
      )}

      {isUniverseEnabledForAvatarVideo && (
        <li className='chat-feature-list-item'>
          <p id='camera-enabled-label' className='text-lead font-caption-body'>
            <span className='chatfeatures icon-default-camera' />
            {translate(FeatureGameDetails.LabelCamera)}
          </p>
        </li>
      )}

      {!isUniverseEnabledForVoice && !isUniverseEnabledForAvatarVideo && (
        <li>
          <p id='enabled-chat-features-label' className='text-lead font-caption-body'>
            {translate(FeatureGameDetails.LabelNone)}
          </p>
        </li>
      )}
    </ul>
  );
};

export default GameDescriptionTableChatFeaturesDisplay;
