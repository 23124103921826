import React from 'react';
import { Tooltip } from 'react-style-guide';
import { WithTranslationsProps, withTranslations } from 'react-utilities';
import { FeatureGearOptionsDisplay } from '../../common/constants/translationConstants';
import { getGearIconName, humanizeCamelCase } from '../../common/utils/parsingUtils';
import { TGearCategoryType } from '../../common/types/bedev1Types';
import { gearTranslationConfig } from '../translation.config';
import '../../../../css/gameDetails/_description.scss';

export type TGearOptionsDisplayProps = {
  genre: string;
  isGenreEnforced: boolean;
  isAllGenre: boolean;
  allowedGearCategories: TGearCategoryType[];
};

export const GearOptionsDisplay = ({
  genre,
  isGenreEnforced,
  isAllGenre,
  allowedGearCategories,
  translate
}: TGearOptionsDisplayProps & { translate: WithTranslationsProps['translate'] }): JSX.Element => {
  const gearStatus = (): React.ReactNode => {
    if (allowedGearCategories.length > 0 && isGenreEnforced && isAllGenre) {
      return (
        <Tooltip
          placement='bottom'
          id={translate(FeatureGearOptionsDisplay.LabelAllGenreAllowed)}
          content={translate(FeatureGearOptionsDisplay.LabelAllGenreAllowed)}>
          <span className='icon-allgears' />
        </Tooltip>
      );
    }
    if (allowedGearCategories.length > 0 && isGenreEnforced) {
      return (
        <Tooltip
          placement='bottom'
          id={translate(FeatureGearOptionsDisplay.LabelGearOnly, {
            GearName: humanizeCamelCase(genre)
          })}
          content={translate(FeatureGearOptionsDisplay.LabelGearOnly, {
            GearName: humanizeCamelCase(genre)
          })}>
          <span className='icon-partialgears' />
        </Tooltip>
      );
    }
    if (allowedGearCategories.length === 0) {
      return (
        <Tooltip
          placement='bottom'
          id={translate(FeatureGearOptionsDisplay.LabelNoGear)}
          content={translate(FeatureGearOptionsDisplay.LabelNoGear)}>
          <span className='icon-nogear' />
        </Tooltip>
      );
    }

    return undefined;
  };

  return (
    <React.Fragment>
      {gearStatus()}
      {allowedGearCategories.map(gear => (
        <Tooltip
          key={gear}
          placement='bottom'
          id={humanizeCamelCase(gear)}
          content={humanizeCamelCase(gear)}>
          <span className={`icon-${getGearIconName(gear)}`} />
        </Tooltip>
      ))}
    </React.Fragment>
  );
};

export default withTranslations<TGearOptionsDisplayProps>(
  GearOptionsDisplay,
  gearTranslationConfig
);
